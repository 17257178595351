/* scrollbar design */

/* width */

::-webkit-scrollbar {
    width: 2px !important;
    height: 2px !important;
  }
  
  /* Track */
  
  ::-webkit-scrollbar-track {
    background: rgb(230, 235, 233);
  }
  
  /* Handle */
  
  ::-webkit-scrollbar-thumb {
    background: #002e22;
    border-radius: 9px;
  }
  
  /* Handle on hover */
  
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(0, 112, 69);
  }
  
  .brand-color {
    color: #4ab597;
  }
  
  .brand-color-button {
    background-color: #0097a7;
  }
  
  btn:focus,
  a:focus {
    outline: none;
  }
  .navbar-toggler:focus {
    outline: none !important;
    box-shadow: none;
  }
  
  .poster-style {
    font-size: "1.8rem";
    line-height: "1.6";
    color: "black";
    font-family: "monospace";
  }
  
  @media print {
    body * {
      visibility: hidden;
    }
    #section-to-print,
    #section-to-print * {
      visibility: visible;
      -webkit-print-color-adjust: exact;
    }
    #section-to-print {
      position: absolute;
      left: 0;
      top: 0;
      -webkit-print-color-adjust: exact;
    }
    @page {
      size: auto; /* auto is the initial value */
  
      /* this affects the margin in the printer settings */
      margin: 0mm 0mm 0mm 0mm;
      padding: 0mm 0mm 0mm 0mm;
    }
  }
  @page {
    size: auto; /* auto is the initial value */
  
    /* this affects the margin in the printer settings */
    margin: 0mm 0mm 0mm 0mm;
    padding: 0mm 0mm 0mm 0mm;
  }
  
  .vertical-middle-aligned {
    vertical-align: middle !important;
  }
  
  caption {
    caption-side: top;
  }
  
  button:focus {
    outline: none;
  }
  
  .dropdown-menu{
    /* max-height: 200px;
    overflow-y: scroll; */
    z-index: 100000 !important;
  }